import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuthStore } from '../stores/authStore';
import { useSubscriptionStore } from '../stores/subscriptionStore';
import { Company, NewCompany, UpdateCompany } from '../types/company';

export const useCompanies = () => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { user } = useAuthStore();
  const { currentSubscription } = useSubscriptionStore();

  // Vérifier si l'utilisateur a un abonnement pro actif
  const isPro = currentSubscription?.plan === 'pro' && currentSubscription?.status === 'active';
  const maxCompanies = isPro ? 5 : 1;

  useEffect(() => {
    if (!user?.id) return;

    const fetchCompanies = async () => {
      try {
        setLoading(true);
        const companiesRef = collection(db, 'companies');
        const q = query(companiesRef, where('userId', '==', user.id));
        const querySnapshot = await getDocs(q);
        
        const fetchedCompanies = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Company[];

        // If user has more companies than allowed by their current plan,
        // keep only the main company or the first one
        if (fetchedCompanies.length > maxCompanies) {
          const mainCompany = fetchedCompanies.find(c => c.isMain);
          const companiesToKeep = mainCompany 
            ? [mainCompany]
            : [fetchedCompanies[0]];
          
          // Delete excess companies from Firestore
          const companiesToDelete = fetchedCompanies
            .filter(c => !companiesToKeep.find(keep => keep.id === c.id));
          
          await Promise.all(
            companiesToDelete.map(c => deleteDoc(doc(db, 'companies', c.id)))
          );

          setCompanies(companiesToKeep);
        } else {
          setCompanies(fetchedCompanies);
        }
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Failed to fetch companies'));
      } finally {
        setLoading(false);
      }
    };

    fetchCompanies();
  }, [user?.id, maxCompanies]);

  const addCompany = async (companyData: NewCompany) => {
    if (!user?.id) throw new Error('User not authenticated');
    if (companies.length >= maxCompanies) {
      throw new Error(
        maxCompanies === 1
          ? 'Vous ne pouvez avoir qu\'une seule société avec le plan gratuit. Passez au plan Pro pour en ajouter plus.'
          : 'Nombre maximum de sociétés atteint pour votre plan d\'abonnement'
      );
    }

    try {
      const companiesRef = collection(db, 'companies');
      const docRef = await addDoc(companiesRef, {
        ...companyData,
        userId: user.id,
        createdAt: new Date().toISOString()
      });

      // Update local state without returning the new company
      setCompanies([...companies, { ...companyData, id: docRef.id, userId: user.id }]);
    } catch (error) {
      console.error('Error adding company:', error);
      throw error;
    }
  };

  const updateCompany = async (id: string, updates: UpdateCompany) => {
    if (!user?.id) throw new Error('User not authenticated');

    try {
      const companyRef = doc(db, 'companies', id);
      await updateDoc(companyRef, {
        ...updates,
        updatedAt: new Date().toISOString()
      });

      setCompanies(prev =>
        prev.map(company =>
          company.id === id ? { ...company, ...updates } : company
        )
      );
    } catch (err) {
      throw err instanceof Error ? err : new Error('Failed to update company');
    }
  };

  const deleteCompany = async (id: string) => {
    if (!user?.id) throw new Error('User not authenticated');

    // Don't allow deleting the last company
    if (companies.length === 1) {
      throw new Error('Vous devez conserver au moins une société');
    }

    try {
      const companyRef = doc(db, 'companies', id);
      await deleteDoc(companyRef);
      
      // If deleting the main company, make another one main
      const deletedCompany = companies.find(c => c.id === id);
      if (deletedCompany?.isMain && companies.length > 1) {
        const nextMainCompany = companies.find(c => c.id !== id);
        if (nextMainCompany) {
          await updateCompany(nextMainCompany.id, { isMain: true });
        }
      }

      setCompanies(prev => prev.filter(company => company.id !== id));
    } catch (err) {
      throw err instanceof Error ? err : new Error('Failed to delete company');
    }
  };

  return {
    companies,
    loading,
    error,
    addCompany,
    updateCompany,
    deleteCompany,
    maxCompanies
  };
};
