import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Stack,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  Alert,
  Grid,
  Autocomplete,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { fr } from 'date-fns/locale';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
  LocationOn as LocationOnIcon,
} from '@mui/icons-material';
import { searchCompanyBySiret } from '../../services/inseeApi';
import { searchAddresses, getPlaceDetails, PlaceAutocompleteResult } from '../../services/placesApi';
import { useAuthStore } from '../../stores/authStore';
import debounce from 'lodash/debounce';
import { Company, NewCompany, UpdateCompany } from '../../types/company';

interface CompanyListProps {
  companies: Company[];
  onAddCompany: (company: NewCompany) => Promise<void>;
  onUpdateCompany: (id: string, company: UpdateCompany) => Promise<void>;
  onDeleteCompany: (id: string) => Promise<void>;
  maxCompanies: number;
}

interface LegalStatusOption {
  value: string;
  label: string;
}

const LEGAL_STATUS_OPTIONS: LegalStatusOption[] = [
  { value: "EI", label: "Entreprise Individuelle (EI)" },
  { value: "ME", label: "Micro-entreprise" },
  { value: "EURL", label: "Entreprise Unipersonnelle à Responsabilité Limitée (EURL)" },
  { value: "SARL", label: "Société à Responsabilité Limitée (SARL)" },
  { value: "SASU", label: "Société par Actions Simplifiée Unipersonnelle (SASU)" },
  { value: "SAS", label: "Société par Actions Simplifiée (SAS)" },
  { value: "SA", label: "Société Anonyme (SA)" },
  { value: "SNC", label: "Société en Nom Collectif (SNC)" },
  { value: "SCS", label: "Société en Commandite Simple (SCS)" },
  { value: "SCA", label: "Société en Commandite par Actions (SCA)" },
  { value: "SCI", label: "Société Civile Immobilière (SCI)" },
  { value: "SCP", label: "Société Civile Professionnelle (SCP)" },
  { value: "SELARL", label: "Société d'Exercice Libéral à Responsabilité Limitée (SELARL)" },
  { value: "SCOP", label: "Société Coopérative de Production (SCOP)" },
  { value: "GIE", label: "Groupement d'Intérêt Économique (GIE)" },
];

export const CompanyList: React.FC<CompanyListProps> = ({
  companies,
  onAddCompany,
  onUpdateCompany,
  onDeleteCompany,
  maxCompanies,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [siret, setSiret] = useState('');
  const [editingCompany, setEditingCompany] = useState<Company | null>(null);
  const [isManualMode, setIsManualMode] = useState(false);
  const [manualCompanyData, setManualCompanyData] = useState({
    companyName: '',
    address: '',
    postalCode: '',
    city: '',
    country: 'France',
    legalStatus: '',
    creationDate: null as Date | null,
    apeCode: '',
    vatNumber: '',
  });
  const [touched, setTouched] = useState({
    companyName: false,
    address: false,
    postalCode: false,
    city: false,
    legalStatus: false,
  });
  const [addressSuggestions, setAddressSuggestions] = useState<PlaceAutocompleteResult[]>([]);
  const [isLoadingAddress, setIsLoadingAddress] = useState(false);
  const { user } = useAuthStore();

  const handleClose = () => {
    setOpen(false);
    setSiret('');
    setError(null);
    setEditingCompany(null);
    setIsManualMode(false);
    setManualCompanyData({
      companyName: '',
      address: '',
      postalCode: '',
      city: '',
      country: 'France',
      legalStatus: '',
      creationDate: null,
      apeCode: '',
      vatNumber: '',
    });
    setTouched({
      companyName: false,
      address: false,
      postalCode: false,
      city: false,
      legalStatus: false,
    });
  };

  const handleSearchSiret = async () => {
    if (!siret || siret.length !== 14) {
      setError('Le SIRET doit contenir 14 chiffres');
      return;
    }

    try {
      setLoading(true);
      setError(null);
      const companyData = await searchCompanyBySiret(siret);

      if (editingCompany) {
        await onUpdateCompany(editingCompany.id, {
          ...companyData,
          name: companyData.companyName,
          isMain: editingCompany.isMain,
          creationDate: companyData.creationDate ? new Date(companyData.creationDate) : undefined,
        });
      } else {
        await onAddCompany({
          ...companyData,
          name: companyData.companyName,
          isMain: companies.length === 0,
          creationDate: companyData.creationDate ? new Date(companyData.creationDate) : new Date(),
          legalStatus: 'SAS', // Set default value since it's not provided in SiretResponse
          userId: user?.id ?? ''
        });
      }

      handleClose();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Une erreur est survenue');
    } finally {
      setLoading(false);
    }
  };

  const handleManualSubmit = async () => {
    try {
      setLoading(true);
      setError(null);

      if (
        !manualCompanyData.companyName ||
        !manualCompanyData.address ||
        !manualCompanyData.postalCode ||
        !manualCompanyData.city ||
        !manualCompanyData.legalStatus
      ) {
        setError('Tous les champs sont obligatoires');
        return;
      }

      const companyData = {
        siret: siret,
        companyName: manualCompanyData.companyName,
        address: manualCompanyData.address,
        postalCode: manualCompanyData.postalCode,
        city: manualCompanyData.city,
        country: manualCompanyData.country,
        legalStatus: manualCompanyData.legalStatus,
        creationDate: manualCompanyData.creationDate,
        apeCode: manualCompanyData.apeCode,
        vatNumber: manualCompanyData.vatNumber,
      };

      if (editingCompany) {
        await onUpdateCompany(editingCompany.id, {
          ...companyData,
          name: companyData.companyName,
          isMain: editingCompany.isMain,
          creationDate: companyData.creationDate ? new Date(companyData.creationDate) : undefined,
        });
      } else {
        await onAddCompany({
          ...companyData,
          name: companyData.companyName,
          isMain: companies.length === 0,
          creationDate: companyData.creationDate ? new Date(companyData.creationDate) : new Date(),
          userId: user?.id ?? ''
        });
      }

      handleClose();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Une erreur est survenue');
    } finally {
      setLoading(false);
    }
  };

  const handleAddressSearch = debounce(async (input: string) => {
    if (!input) {
      setAddressSuggestions([]);
      return;
    }

    try {
      setIsLoadingAddress(true);
      const results = await searchAddresses(input);
      setAddressSuggestions(results);
    } catch (err) {
      console.error('Error searching addresses:', err);
    } finally {
      setIsLoadingAddress(false);
    }
  }, 300);

  const handleAddressSelect = async (place: PlaceAutocompleteResult | null) => {
    if (!place) return;

    try {
      setIsLoadingAddress(true);
      const details = await getPlaceDetails(place.placeId);
      setManualCompanyData(prev => ({
        ...prev,
        address: details.address,
        postalCode: details.postalCode,
        city: details.city,
        country: details.country,
      }));
    } catch (err) {
      console.error('Error getting place details:', err);
    } finally {
      setIsLoadingAddress(false);
    }
  };

  const handleEdit = (company: Company) => {
    setEditingCompany(company);
    setSiret(company.siret);
    setOpen(true);
  };

  const handleDelete = async (id: string) => {
    try {
      await onDeleteCompany(id);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Erreur lors de la suppression');
    }
  };

  const handleSetMain = async (id: string) => {
    try {
      // Update all companies to not be main
      await Promise.all(
        companies.map((company) =>
          onUpdateCompany(company.id, { isMain: company.id === id })
        )
      );
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Erreur lors de la mise à jour');
    }
  };

  return (
    <>
      <Card elevation={0} sx={{ bgcolor: 'background.default' }}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
            <Typography variant="h6">Mes Sociétés</Typography>
            <Button
              startIcon={<AddIcon />}
              onClick={() => setOpen(true)}
              variant="contained"
              size="small"
            >
              Ajouter une société
            </Button>
          </Box>

          <Stack spacing={2}>
            {companies.map((company) => (
              <Card
                key={company.id}
                variant="outlined"
                sx={{
                  position: 'relative',
                  borderColor: company.isMain ? 'primary.main' : 'divider',
                  '&:hover': {
                    borderColor: company.isMain ? 'primary.main' : 'text.secondary',
                  },
                }}
              >
                {company.isMain && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: -1,
                      right: 16,
                      bgcolor: 'primary.main',
                      color: 'primary.contrastText',
                      px: 1,
                      py: 0.5,
                      borderBottomLeftRadius: 4,
                      borderBottomRightRadius: 4,
                      fontSize: '0.75rem',
                    }}
                  >
                    Principale
                  </Box>
                )}

                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                      <Typography variant="subtitle1" gutterBottom>
                        {company.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        SIRET: {company.siret}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {company.address}, {company.postalCode} {company.city}
                      </Typography>
                    </Box>
                    <Stack direction="row" spacing={1}>
                      {!company.isMain && (
                        <Tooltip title="Définir comme société principale">
                          <Button
                            size="small"
                            variant="outlined"
                            onClick={() => handleSetMain(company.id)}
                          >
                            Définir principale
                          </Button>
                        </Tooltip>
                      )}
                      <Tooltip title="Modifier">
                        <IconButton
                          size="small"
                          onClick={() => handleEdit(company)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      {!company.isMain && (
                        <Tooltip title="Supprimer">
                          <IconButton
                            size="small"
                            onClick={() => handleDelete(company.id)}
                            color="error"
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Stack>
                  </Box>
                </CardContent>
              </Card>
            ))}

            {companies.length === 0 && (
              <Box
                sx={{
                  p: 3,
                  textAlign: 'center',
                  border: '2px dashed',
                  borderColor: 'divider',
                  borderRadius: 1,
                }}
              >
                <Typography color="text.secondary">
                  Aucune société ajoutée
                </Typography>
              </Box>
            )}
          </Stack>
        </CardContent>
      </Card>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {editingCompany ? 'Modifier la société' : 'Ajouter une société'}
        </DialogTitle>
        <DialogContent>
          {error && (
            <Alert
              severity="error"
              sx={{ mb: 2 }}
              action={
                !isManualMode && (
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => setIsManualMode(true)}
                  >
                    Saisie manuelle
                  </Button>
                )
              }
            >
              {error}
            </Alert>
          )}

          {!isManualMode ? (
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label="SIRET"
                value={siret}
                onChange={(e) => setSiret(e.target.value.replace(/\D/g, '').slice(0, 14))}
                disabled={loading}
                required
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={handleSearchSiret}
                      disabled={loading || siret.length !== 14}
                    >
                      {loading ? (
                        <CircularProgress size={20} />
                      ) : (
                        <SearchIcon />
                      )}
                    </IconButton>
                  ),
                }}
                helperText={
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>Entrez le numéro SIRET pour rechercher automatiquement les informations</span>
                    <Button
                      size="small"
                      onClick={() => setIsManualMode(true)}
                    >
                      Saisie manuelle
                    </Button>
                  </Box>
                }
              />
            </Box>
          ) : (
            <Box sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="SIRET"
                    value={siret}
                    onChange={(e) => setSiret(e.target.value.replace(/\D/g, '').slice(0, 14))}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Nom de la société"
                    value={manualCompanyData.companyName}
                    onChange={(e) => setManualCompanyData(prev => ({ ...prev, companyName: e.target.value }))}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete<LegalStatusOption, false, false, false>
                    fullWidth
                    options={LEGAL_STATUS_OPTIONS}
                    value={
                      manualCompanyData.legalStatus
                        ? {
                            value: manualCompanyData.legalStatus,
                            label: manualCompanyData.legalStatus
                          }
                        : undefined
                    }
                    onChange={(_, newValue) => {
                      let newLegalStatus = '';
                      if (typeof newValue === 'string') {
                        newLegalStatus = newValue;
                      } else if (newValue && newValue.label) {
                        newLegalStatus = newValue.label;
                      }
                      setManualCompanyData(prev => ({
                        ...prev,
                        legalStatus: newLegalStatus
                      }));
                      setTouched(prev => ({
                        ...prev,
                        legalStatus: true
                      }));
                    }}
                    onInputChange={(_, newInputValue, reason) => {
                      if (reason === 'input') {
                        setManualCompanyData(prev => ({
                          ...prev,
                          legalStatus: newInputValue
                        }));
                      }
                    }}
                    getOptionLabel={(option) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      return option.label;
                    }}
                    renderOption={(props, option, state) => {
                      const { key, ...otherProps } = props;
                      return (
                        <li key={key} {...otherProps}>
                          {option.label}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Forme juridique"
                        required
                        error={touched.legalStatus && !manualCompanyData.legalStatus}
                        helperText={
                          touched.legalStatus && !manualCompanyData.legalStatus
                            ? 'La forme juridique est requise'
                            : ''
                        }
                      />
                    )}
                    autoSelect
                    selectOnFocus
                  />
                </Grid>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
                    <DatePicker
                      label="Date de création"
                      value={manualCompanyData.creationDate}
                      onChange={(date) => setManualCompanyData(prev => ({ ...prev, creationDate: date }))}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          required: true,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    fullWidth
                    options={addressSuggestions}
                    loading={isLoadingAddress}
                    getOptionLabel={(option) => option.description}
                    onChange={(_, value) => handleAddressSelect(value)}
                    onInputChange={(_, value) => handleAddressSearch(value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Rechercher une adresse"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              <LocationOnIcon color="action" />
                              {params.InputProps.startAdornment}
                            </>
                          ),
                          endAdornment: (
                            <>
                              {isLoadingAddress ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Adresse"
                    value={manualCompanyData.address}
                    onChange={(e) => setManualCompanyData(prev => ({ ...prev, address: e.target.value }))}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Code postal"
                    value={manualCompanyData.postalCode}
                    onChange={(e) => setManualCompanyData(prev => ({ ...prev, postalCode: e.target.value }))}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Ville"
                    value={manualCompanyData.city}
                    onChange={(e) => setManualCompanyData(prev => ({ ...prev, city: e.target.value }))}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Code APE"
                    value={manualCompanyData.apeCode}
                    onChange={(e) => setManualCompanyData(prev => ({ ...prev, apeCode: e.target.value }))}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Numéro de TVA"
                    value={manualCompanyData.vatNumber}
                    onChange={(e) => setManualCompanyData(prev => ({ ...prev, vatNumber: e.target.value }))}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          {isManualMode && (
            <Button onClick={() => setIsManualMode(false)}>
              Retour à la recherche SIRET
            </Button>
          )}
          <Button onClick={handleClose}>Annuler</Button>
          <Button
            onClick={isManualMode ? handleManualSubmit : handleSearchSiret}
            variant="contained"
            disabled={loading || (!isManualMode && siret.length !== 14)}
          >
            {loading ? (
              <CircularProgress size={24} />
            ) : editingCompany ? (
              'Mettre à jour'
            ) : (
              'Ajouter'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
