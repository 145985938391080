import { create } from 'zustand';
import { collection, query, where, getDocs, onSnapshot } from 'firebase/firestore';
import { db } from '../config/firebase';

export interface Subscription {
  id: string;
  userId: string;
  plan: 'free' | 'pro';
  status: 'active' | 'canceled' | 'past_due';
  currentPeriodEnd: string;
  stripeSubscriptionId: string;
  stripeCustomerId: string;
}

interface SubscriptionStore {
  currentSubscription: Subscription | null;
  isLoading: boolean;
  error: Error | null;
  fetchSubscription: (userId: string) => Promise<void>;
  subscribeToChanges: (userId: string) => () => void;
}

export const useSubscriptionStore = create<SubscriptionStore>((set) => ({
  currentSubscription: null,
  isLoading: false,
  error: null,

  fetchSubscription: async (userId: string) => {
    try {
      set({ isLoading: true, error: null });
      const subscriptionsRef = collection(db, 'subscriptions');
      const q = query(
        subscriptionsRef,
        where('userId', '==', userId),
        where('status', '==', 'active'),
        where('currentPeriodEnd', '>', new Date().toISOString())
      );
      
      const querySnapshot = await getDocs(q);
      const subscription = querySnapshot.docs[0]?.data() as Subscription | undefined;
      
      // S'assurer que l'abonnement est valide
      const isValid = subscription && 
        subscription.status === 'active' && 
        new Date(subscription.currentPeriodEnd) > new Date();
      
      set({
        currentSubscription: isValid ? subscription : null,
        isLoading: false
      });
    } catch (error) {
      set({
        error: error instanceof Error ? error : new Error('Failed to fetch subscription'),
        isLoading: false
      });
    }
  },

  subscribeToChanges: (userId: string) => {
    const subscriptionsRef = collection(db, 'subscriptions');
    const q = query(
      subscriptionsRef,
      where('userId', '==', userId),
      where('status', '==', 'active')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const subscription = snapshot.docs[0]?.data() as Subscription | undefined;
      set({ currentSubscription: subscription || null });
    }, (error) => {
      set({
        error: error instanceof Error ? error : new Error('Failed to subscribe to changes'),
        isLoading: false
      });
    });

    return unsubscribe;
  }
}));
