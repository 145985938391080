import { useQuery } from '@tanstack/react-query';
import { getSubscriptionStatus } from '../services/stripe';
import { useAuthStore } from '../stores/authStore';

export const useSubscription = () => {
  const { user } = useAuthStore();

  return useQuery({
    queryKey: ['subscription', user?.id],
    queryFn: () => user?.id ? getSubscriptionStatus(user.id) : null,
    enabled: !!user?.id,
  });
};